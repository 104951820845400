<template>
    <div class="flex flex-col justify-end" :class="[ checkRootNameAndBanners() ? 'footer' : '']">
        <div class="wrapper w-full text-xs flex justify-center pb-12 pt-5 mb-10 h-32">
            <div :class="$root.hotel.site == 'moon2.2roomz.com' ? 'text-white' : ''">{{ getCoName() }} &copy;{{ new Date().getFullYear() }}</div>
            <a href="javascript:void(0)" @click="openPrivacyPolicy" class="ml-2 underline">{{ $root.getKeyWord('privacy_policy') }}</a>
        </div>
    </div>
</template>

<script>
export default {
    
    name: 'AppFooter',
    methods: {
        checkRootNameAndBanners() {
            return this.$route.name === 'home' && this.$root.hotel && this.$root.hotel.banners && this.$root.hotel.banners.length > 0;
        },

        openPrivacyPolicy() {
            this.$modal.show('modal-privacy-policy');
            this.$root.getPublicInformation('privacy_policy');
        },
    }
}
</script>
