<template>
    <a-drawer wrapClassName="drawer-custom" placement="bottom"  :height="'89%'"  :closable="false" @close="onClose" :visible="$parent.visibleBottomCart">
           <div class="flex justify-end">
                <a href="javascript:void(0)" @click="$parent.visibleBottomCart = false" class="block hover:bg-gray-200 rounded-full p-1">
                    <svg xmlns='http://www.w3.org/2000/svg' class='fill-current text-gray-800 rounded-full' viewBox='0 0 512 512'  height="32" width="32"><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg>
                </a>
            </div>
            <div class="mt-40 w-full flex flex-col items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-32 h-32 opacity-20"><path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"></path></svg>
                <div class="text-gray-600 text-lg my-10">{{ $root.getKeyWord('no_orders_status') }}</div>
            </div>  
    </a-drawer>    
</template>

<script>

export default {
    name: 'AppBottomCart',

    methods: {
        onClose() {
            this.$parent.visibleBottomCart = false;
        }, 
    }    
}
</script>
<style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style>