export const CaptchaMixin = {
    methods: {
        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
        },
        getClientIp() {
            this.$http.get(process.env.VUE_APP_API_URL + 'get-ip').then(response => {
                this.ip_addr = response.data.ip;
            });
        },

    },
};

