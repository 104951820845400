<template>
    <div>
        <select v-model="s_item_property.value" class="block appearance-none w-full bg-white border border-gray-200 hover:border-gray-300 px-4 py-2 pr-8 text-sm rounded leading-tight focus:outline-none focus:shadow-outline">
          <option v-for="(item, index) in getTimeRange()" :key="index" v-text="item"></option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>    
    </div>
</template>

<script>
import moment from 'moment-timezone';


export default {
    props: ['itemProperty', 'properties'],

    computed: {
        s_item_property: {
            get() {
                return this.itemProperty
            },

            set(val) {
                this.$emit('update:itemProperty', val)
            },
        },

        dateFormat() {
            return process.env.VUE_APP_SERVER.toLowerCase() == 'ru' ? 'DD-MM-YYYY' : 'YYYY-MM-DD';
        }
    },

    methods: {
        getTimeRange() {
            let start = moment('2020-04-20 00:00', 'YYYY-MM-DD H:mm');
            let end = moment('2020-04-20 23:59', 'YYYY-MM-DD H:mm');
            let nowTime = moment();
            let calendarDate = moment();

            var dateProp = this.properties.filter(item => item.ptype_id == 'date');
            if (dateProp.length > 0 && dateProp[0].value) {
                calendarDate = moment(dateProp[0].value, this.dateFormat);
            }

            if ( this.s_item_property.schedule_id !== null) {
                var activeScheduleDays = [];
                this.s_item_property.schedule_kit.days.map(d_item => {
                    if (d_item.days.filter((day, index) => day && calendarDate.day() == index).length > 0)
                        activeScheduleDays.push(d_item);
                });

                if (activeScheduleDays.length > 0) {
                    start = moment(activeScheduleDays[0].start_time, 'HH:mm:ss');
                    end = moment(activeScheduleDays[0].end_time, 'HH:mm:ss');
                }                
            }

            try {
                // do same time for diff
                end.set("year", 2020);
                end.set("month", 1);
                end.set("date", 20);

                start.set("year", 2020);
                start.set("month", 1);
                start.set("date", 20);

                nowTime.set("year", 2020);
                nowTime.set("month", 1);
                nowTime.set("date", 20);            
                

                if (dateProp.length > 0 && dateProp[0].value == moment().format(this.dateFormat) && start.diff(nowTime) < 0) {                    
                    start = moment();
                    end.set({'year': moment().get('year'), 'month': moment().get('month'), 'date': moment().get('date')});
                    console.log('end date', end.format("YYYY-MM-DD"));
                    
                    var interval = this.s_item_property.extra.time_interval !== undefined && this.s_item_property.extra.time_interval !== null 
                        ? parseInt(this.s_item_property.extra.time_interval) 
                        : 30;

                    console.log(interval);
                    var roundTime = this.roundTime(start.format('HH:mm'), interval);
                    console.log(roundTime);
                    /*start = moment(new Date());*/
                    start.set({hour: roundTime.hour, minute: roundTime.min, second: 0, millisecond: 0});
                    start.add(interval, 'minutes');
                    console.log('start', start);
                    console.log('end', end);

                    if (start.diff(end) > 0)
                        return false;
                }                

                return this.loopStartAndEndTimes(this.s_item_property, start, end);
            } catch (e) {
                return this.loopStartAndEndTimes(this.s_item_property, start, end);
            }
        },

        loopStartAndEndTimes(item, start, end) {
            if (start > end) {
                end = end.add(1, 'days');
            }
            
            let items = [];
            var interval = item.extra.time_interval !== undefined && item.extra.time_interval !== null ? item.extra.time_interval : 30;
            for (let m = moment(start); m.isBefore(end); m.add(interval, 'minutes')) {
                items.push(m.format('H:mm'));
            }
            items.push(end.format('H:mm'));

            return items;
        },

        roundTime(time, minutesToRound) {
            let [hours, minutes] = time.split(':');
            hours = parseInt(hours);
            minutes = parseInt(minutes);

            // Convert hours and minutes to time in minutes
            time = (hours * 60) + minutes; 

            let rounded = Math.round(time / minutesToRound) * minutesToRound;
            let rHr = ''+Math.floor(rounded / 60)
            let rMin = ''+ rounded % 60

            return {'hour': rHr, 'min':rMin};
        },
        
        checkScheduleInTime(currentDate, item, timezone) {
            var start_time = item.start_time;
            var end_time = item.end_time;

            var currentCopyDate = new Date(moment.tz(new Date(), timezone).format('YYYY/MM/DD HH:mm:ss'));
            currentCopyDate.setHours(start_time.split(':')[0]);
            currentCopyDate.setMinutes(start_time.split(':')[1]);
            currentCopyDate.setSeconds(0);
            start_time = currentCopyDate;

            var h_end = end_time.split(':')[0];
            var m_end = end_time.split(':')[1];

            currentCopyDate = new Date(moment.tz(new Date(), timezone).format('YYYY/MM/DD HH:mm:ss'));
            currentCopyDate.setHours(h_end);
            currentCopyDate.setMinutes(m_end);
            currentCopyDate.setSeconds(0);
            end_time = currentCopyDate;

            if (start_time >= end_time) {
                var start_time_mo = moment(start_time)
                    .subtract(h_end, 'hours')
                    .subtract(m_end, 'minutes')
                    .subtract(1, 'seconds');

                var end_time_mo = moment(end_time)
                    .subtract(h_end, 'hours')
                    .subtract(m_end, 'minutes')
                    .subtract(1, 'seconds');

                var current_time_mo = moment()
                    .subtract(h_end, 'hours')
                    .subtract(m_end, 'minutes')
                    .subtract(1, 'seconds');

                var new_start_time = this.setHoursAndMinutes(start_time_mo.hours(), start_time_mo.minutes(), start_time_mo.seconds(), timezone);
                var new_end_time = this.setHoursAndMinutes(end_time_mo.hours(), end_time_mo.minutes(), end_time_mo.seconds(), timezone);
                var new_current_time = this.setHoursAndMinutes(current_time_mo.hours(), current_time_mo.minutes(), current_time_mo.seconds(), timezone);

                return new_current_time.isBetween(new_start_time, new_end_time)
            }

            return currentDate >= start_time && currentDate <= end_time;
        },

        setHoursAndMinutes(h, m, s, timezone) {
            var currentCopyDate = new Date(moment.tz(new Date(), timezone).format('YYYY/MM/DD HH:mm:ss'));
            currentCopyDate.setHours(h);
            currentCopyDate.setMinutes(m);
            currentCopyDate.setSeconds(s);
            return moment(currentCopyDate);
        },        
    }
}
</script>