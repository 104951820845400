<template>
    <div class="flex gap-1 justify-between text-base text-black" v-if="display_timer">
        <div>{{ $root.getKeyWord('est_delivery_time') }} </div>
        <div class="text-xs font-medium px-2.5 py-0.5 rounded border flex items-center gap-1" 
            :class="[display_timer == 'Expired' ? 'text-red-600 bg-red-100 border-red-400' : 'text-blue-800 bg-blue-100 border-blue-400']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <label>{{ display_timer }}</label>
        </div>
    </div>
</template>

<script>
const moment = require('moment');

export default {
    props: ["expectedTimeleft"],

    data() {
        return {
            timer: null,
            display_timer: null,
            interval: 1000,
        }
    },
    
    created() {
        this.countDownTimer();
    },

    watch: {
        expectedTimeleft(val, oldVal) {
            clearInterval(this.timer);
            this.countDownTimer();
        }
    },

    methods: {
        countDownTimer() {
            if (!this.expectedTimeleft)
                return;
        
            var expiredText = this.$root.getKeyWord('exp_delivery_time') ;

            if (this.expectedTimeleft <= 60)  {
                this.display_timer = expiredText;
                return;
            }

            var countDownDate = moment().add(parseInt(this.expectedTimeleft), 'seconds');

            this.timer = setInterval(() => {
                var diff = countDownDate.diff(moment());
                this.display_timer = '~' + moment.utc(diff).format("H [hrs], m [min]").toString().replace('0 hrs, ', '');
                if (Math.floor(diff / 60000) <= 0) {
                    clearInterval(this.timer);
                    this.display_timer = expiredText;
                }     

            }, 1000);
        }
    }
}
</script>