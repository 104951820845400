<template>
<div>
    <div v-for="order in orders" :key="order.id">
        <notification-order-item :order="order"></notification-order-item>
    </div>
</div>    
</template>

<script>
import notificationOrderItem from './ArticleNotificationOrderItem.vue';
export default {
    components: { notificationOrderItem },

    props: ['orders', 'orderType'],
}
</script>