<template>
    <div>
        <div v-if="$root.hotelCode == 'novotelschiphol'" class="m-5 md:grid md:grid-cols-2 gap-2 flex flex-col items-start">
            <div>
                <div class="font-medium text-gray-800">Chat with the Manager on Duty.</div>
                <div class="text-gray-500">Like to talk? Call me on <span class="text-blue-600">380</span>.</div>
            </div>
            <div>
                <div class="font-medium text-gray-800">Want to talk to:</div>
                <div class="text-left leading-tight">
                    <div class="text-gray-500">The Reception, call <span class="text-blue-600">99</span>.</div>
                    <div class="text-gray-500">The Room Service call <span class="text-blue-600">366</span>.</div>
                </div>
            </div>
            <div class="pt-2 font-medium text-gray-800">Fill in the details below to start chatting:</div>
        </div>
        <div v-if="!loading">
            <div class="m-5 p-4 bg-gray-100 rounded-xl">
                <div v-if="$root.hotelCode == 'marriottpolyana'">
                    <div class="mb-6">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900" v-text="$root.getLangText({'ru': 'Фамилия', 'en': 'Last name'})"></label>
                        <input type="text" ref="fname" v-model="fname" id="fname" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required>
                    </div>
                    <div class="mb-6">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900" v-text="$root.getLangText({'ru': 'Имя', 'en': 'First name'})"></label>
                        <input type="text" ref="lname" v-model="lname" id="lname" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required>
                    </div>
                    <div class="mb-6">
                        <label for="phone" class="block mb-2 text-sm font-medium text-gray-900" v-text="$root.getLangText({'ru': 'Номер телефона', 'en': 'Phone number'})"></label>
                        <div class="flex items-center bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <span class="pr-2">+</span>
                            <input type="text" ref="phone" v-model="phone" id="phone" class="w-full" placeholder="" required>
                        </div>
                    </div>
                </div>
                <div class="mb-6" v-else>
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900" v-text="$root.getKeyWord('name')"></label>                
                    <input type="text" ref="name" v-model="name" id="name" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required>
                </div>                
                <div class="flex justify-between items-center mb-4">
                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" v-model="is_hotel_guest" checked>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                        <span class="ml-3 font-medium text-gray-900 peer-checked:block hidden" v-text="$root.getKeyWord('i_am_hotel_guest')"></span> 
                        <span class="ml-3 font-medium text-gray-900 peer-checked:hidden block" v-text="$root.getKeyWord('not_a_hotel_guest')"></span>
                    </label>                                
                </div>                                          
                <div class="mb-6" :class="[errors && errors.get('room_number') ? 'text-red-500' : '']"  v-if="is_hotel_guest">
                    <label for="room_number" class="block mb-2 text-sm font-medium">
                        <span v-if="$root.hotelCode == 'loftelspb'" v-text="$root.getLangText({'ru': 'Название комнаты', 'en': 'Room name'})"></span>
                        <span v-else v-text="$root.getKeyWord('room_number')"></span>
                    </label>
                    <input type="text"  ref="room_number" v-model="room_number" id="room_number" :class="[errors && errors.get('room_number') ? 'border-red-500' : 'border-gray-300']" class="bg-white border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required>
                </div>
                <div class="flex justify-center">
                    <button @click="start()" class="shake-btn block w-full h-full px-4 py-2 rounded-lg focus:outline-none bg-black text-white text-sm" v-text="$root.getKeyWord('start_chat')"></button>
                </div>
            </div>
        </div>

        <div class="wrapper w-full text-xs flex justify-center mb-10 pt-4">
            <div>{{ getCoName() }} &copy;{{ new Date().getFullYear() }}</div>
        </div>
    </div>    
</template>
<script>

class Errors {
    constructor() {
        this.errors = {};
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    searchByKey(field) {
        return Object.keys(this.errors).filter(item => item.indexOf(field) > -1).length > 0;
    }

    record(errors) {
        this.errors = errors;
    }
}

import axios from 'axios';

export default {
    data() {
        return {
            // for all hotesl
            name: '',

            // for specific hotel fname, lname, phone
            fname: '',
            lname: '',
            phone: null,

            room_number: (localStorage.getItem('fs_room_number') != undefined) 
                ? localStorage.getItem('fs_room_number') 
                : '',
            is_hotel_guest: true,
            loading: false,
            person_uuid: (localStorage.getItem('person-uuid') != undefined) 
                ? localStorage.getItem('person-uuid') 
                : null,

            errors: new Errors()
        }
    },

    mounted() {
        if (this.person_uuid)   {
            this.fetchPerson();
        }           
    },

    methods: {
        async fetchPerson() {
            this.loading = true;

            var url = process.env.VUE_APP_API_URL + 'public/hotels/' + this.$root.hotel.uuid + '/guests/' + this.person_uuid;
            var result = await axios.get(url);
            
            this.loading = false;

            var person = result.data.data;
            if (person) {                
                this.name = person.name ? person.name : '';
                this.room_number = person.reservations && Object.values(person.reservations).length > 0 
                    ? person.reservations[0].room_number 
                    : '';                
            }        
        },

        async start() {
            if (this.$root.hotelCode == 'marriottpolyana') {
                if (this.fname.replace(' ', '').length == 0) {
                    this.$nextTick(() => {
                        this.$refs.fname.focus();
                    });
                    return;
                }
                
                if (this.lname.replace(' ', '').length == 0) {
                    this.$nextTick(() => {
                        this.$refs.lname.focus();
                    });
                    return;
                }

                this.name = this.fname + ' ' + this.lname;
            }

            if (this.name == '') {
                this.$nextTick(() => {
                  this.$refs.name.focus();
                });
                return;
            }

            if (this.room_number == '' && this.is_hotel_guest) {
                this.$nextTick(() => {
                  this.$refs.room_number.focus();
                })
                return;
            }

            var url = process.env.VUE_APP_API_URL + 'public/chat/start';

            /** TODO: hotel id need to put **/
            try {
                var response = await this.$http.post(url, {
                    name: this.name,
                    room_number: this.room_number,
                    hotel_uuid: this.$root.hotel.uuid,
                    lang: this.$root.currentLanguage,
                    person_uuid: this.person_uuid,
                    is_hotel_guest: this.is_hotel_guest,
                    phone: this.phone,
                });
            } catch (error) {
                this.loading = false;
                this.errors.record(error.response.data.data);

                return false;
            }

            var results = response.data.data;
            this.chat_uuid = results.uuid;
            this.person_uuid = results.initiator.uuid;

            localStorage.setItem('chat-uuid', results.uuid);
            localStorage.setItem('person-uuid', results.initiator.uuid);
            localStorage.setItem('fs_room_number', this.room_number);

            this.$emit('startChat', results.uuid, results.initiator.uuid);
        },        
    }
}
</script>
<style>
input#phone::-webkit-contacts-auto-fill-button {
    display: none !important;
    visibility: hidden !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
</style>