import moment from 'moment';
import localeEn from 'air-datepicker/locale/en';
import localeRu from 'air-datepicker/locale/ru';
import localeFr from 'air-datepicker/locale/fr';
import localeDe from 'air-datepicker/locale/de';
import localeEs from 'air-datepicker/locale/es';
import localeNl from 'air-datepicker/locale/nl';

export const AirDatePickerMixin = {
    data() {
        return {
            locale: localeEn,
            calendarSheetLength: 34,
        };
    },
    methods: {
        /*
         * set language locale for air-datepicker
         */
        setLocale() {
            switch (this.$root.currentLanguage) {
                case 'ru':
                    this.locale = localeRu;
                    break;
                case 'en':
                    this.locale = localeEn;
                    break;
                case 'fr':
                    this.locale = localeFr;
                    break;
                case 'de':
                    this.locale = localeDe;
                    break;
                case 'es':
                    this.locale = localeEs;
                    break;
                case 'nl':
                    this.locale = localeNl;
                    break;
                default:
                    this.locale = localeEn;
                    break;
            }
        },
        getDatepickerDatesRange(startDate){
            return {
                start: moment(startDate).format('YYYY-MM-DD'),
                end: moment(startDate).add(this.calendarSheetLength, 'day').format('YYYY-MM-DD'),
            };
        }
    },
};

