<template>
    <date-picker class="w-full" :default-value="new Date()" :disabled-date="disabledBeforeTodayAndAfterAWeek" :lang="lang" v-model="s_item_property.value" valueType="format" :format="dateFormat"></date-picker>
</template>

<script>
import moment from 'moment';

export default {
    props: ['itemProperty'],

    data() {
        return {
            lang: 'en',
        }
    },

    computed: {
        s_item_property: {
            get() {
                return this.itemProperty
            },

            set(val) {
                this.$emit('update:itemProperty', val)
            },
        },

        dateFormat() {
            return process.env.VUE_APP_SERVER.toLowerCase() == 'ru' ? 'DD-MM-YYYY' : 'YYYY-MM-DD';
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.lang = this.$root.currentLanguage == 'ru' ? 'ru' : 'en';
        });
    },    

    methods: {
      disabledBeforeTodayAndAfterAWeek(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        var item = this.s_item_property;

        try {
          if (item.schedule_id !== null) {
            /*var timezone = this.$root.hotel.ext_data !== null && this.$root.hotel.ext_data.timezone !== undefined && this.$root.hotel.ext_data.timezone !== ''
                ? this.$root.hotel.ext_data.timezone.replace('posix/', '')
                : '';*/
            var currentDate = moment(date, this.dateFormat);

            // first check days who had start_date an 
            var activeScheduleDays = [];
            var activeScheduleWeeks = [];
            
            item.schedule_kit.days.map(item => {
                var isBetweenDate = false;
                console.log(item);
                if (item.start_date && item.end_date) {
                    var mStartDate = moment(item.start_date, 'YYYY-MM-DD');
                    var mEndDate = moment(item.end_date, 'YYYY-MM-DD');
                    isBetweenDate = currentDate.isBetween(mStartDate, mEndDate) || currentDate.isSame(mStartDate) || currentDate.isSame(mEndDate);                                        
                }

                if (isBetweenDate)
                    activeScheduleDays.push(item);                    
                else if (!item.start_date && !item.end_date)
                    activeScheduleWeeks.push(item);
            });

            console.log(activeScheduleDays);

            var scheduleDays = activeScheduleDays.length > 0 ? activeScheduleDays : activeScheduleWeeks;
            
            if (scheduleDays.length > 0) {
                var isCurrentDateInScheduleActive = scheduleDays.filter(schedule => {                
                    // find equal current day = week day and week day should be active
                    var schedules = schedule.days.filter((weekDay, index) => index == currentDate.day() && weekDay);
                    return schedules.length == 0
                });

                // need to disable all prev days, and disactive schedule days if day is in active
                return date < today || isCurrentDateInScheduleActive.length > 0;
            } else {
                return date < today;
            }
          } 
        } catch(e) {
          console.log(e);
        }

        return date < today;
      },
    }
}
</script>