<template>
<div>
    <announcement-modal v-for="result in results" :announcement="result"></announcement-modal>
</div>
</template>

<script>
import { eventBus } from '../../main';
import AnnouncementModal from './announcement.modal';

export default {
    components: { AnnouncementModal },

    data() {
        return {
            results: null,
            parentSectionId: null,
            image: null,
        }
    },

    created() {  
        eventBus.$on('modal-announcement', data => {            
            this.parentSectionId = data.parent_section_id;
            this.fetch();
        });
    },

    watch: {
        results() {
            this.results.map(item => {
                this.displayAnons(item);
            })
        }
    },
    
    methods: {        
        fetch() {
            this.$http.get(process.env.VUE_APP_API_URL + 'public/announcements?hotel_id=' + this.$root.hotel.id)
                .then(response => {
                    if (response.data && response.data.data && response.data.data.length > 0) {
                        this.results = response.data.data;
                    }
                });
        },

        getDefaultRoomNumber() {
            return (localStorage.getItem('fs_room_number') != undefined) ? localStorage.getItem('fs_room_number') : '';
        },        

        displayAnons(result) {    
            if (location.pathname.indexOf('/chat') > -1) {
                return;
            }          
             
            try {
                var localStorageIdAnons = 'display_announcement_' + result.id + result.updated_at
                if (localStorage.getItem(localStorageIdAnons) === undefined || localStorage.getItem(localStorageIdAnons) === null)  
                {
                    var displayTime = result.extra.web_popup_config.display_time_after_visit_site;
                    var includedSectionIds = result.extra.web_popup_config.include_section_ids;
                    var includedRooms = result.extra.web_popup_config.display_only_room_numbers;
                    var displayEveryRefresh = result.extra.web_popup_config.display_every_refresh;
                    var displayOnlyViaQrCode = result.extra.web_popup_config.hasOwnProperty('display_only_via_qrcode') ? result.extra.web_popup_config.display_only_via_qrcode : false;

                    if (displayOnlyViaQrCode !== undefined && displayOnlyViaQrCode !== null && displayOnlyViaQrCode) {
                        var announcement_id = this.$root.getQueryVariable('announcement_id');
                        if (announcement_id !== undefined && announcement_id && parseInt(announcement_id) > 0 && result.id == parseInt(announcement_id)) {

                        } else {
                            console.log('Anons not right qrcode');
                            return false;
                        }
                    }



                    if (includedSectionIds !== undefined && includedSectionIds !== null && includedSectionIds !== '' && includedSectionIds.length > 0) {
                        if (this.parentSectionId == null && includedSectionIds.includes(null)) {
                            // display only home page
                        } else if (this.parentSectionId == null || !includedSectionIds.includes(this.parentSectionId)) {
                            console.log('Anons not right page via section');
                            return false;
                        }
                    }

                    
                    if (includedRooms !== undefined && includedRooms !== null && includedRooms !== '') {
                        if (this.getDefaultRoomNumber() == '') 
                            return false;
                            
                        var includedRooms = includedRooms.split(',');
                        
                        if (includedRooms.indexOf(this.getDefaultRoomNumber()) < 0) {
                            console.log('Anons not right room number');
                            return false;
                        }
                    }

                    setTimeout(() => {
                        this.$modal.show('modal-announcements-' + result.id);
                    }, parseFloat(displayTime ?? 0) * 60 * 1000);
                    
                    if (displayEveryRefresh === undefined || displayEveryRefresh == null || !displayEveryRefresh)
                        localStorage.setItem(localStorageIdAnons, true);
                }
            } catch(e) {
                console.log(e);
            }
        }
    },
}
</script>