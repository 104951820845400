import { render, staticRenderFns } from "./AppRecommendeProductsForProductsModal.vue?vue&type=template&id=68f4fb12&scoped=true&"
import script from "./AppRecommendeProductsForProductsModal.vue?vue&type=script&lang=js&"
export * from "./AppRecommendeProductsForProductsModal.vue?vue&type=script&lang=js&"
import style0 from "./AppRecommendeProductsForProductsModal.vue?vue&type=style&index=0&id=68f4fb12&prod&lang=css&"
import style1 from "./AppRecommendeProductsForProductsModal.vue?vue&type=style&index=1&id=68f4fb12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f4fb12",
  null
  
)

export default component.exports