export const IsAnyProductGotRoomNumberOptionMixin = {
    methods: {
        isAnyProductGotRoomNumberOption(products) {
            var except_sections = this.$root.hotel.ext_data.properties.room_number.except_sections;

            if (!except_sections) {
                return true;
            }

            return products.filter(item => {
                if (except_sections.indexOf(String(item.parent_section.id)) == -1) {
                    if (item.section_id != undefined) {
                        return except_sections.indexOf(String(item.section_id)) == -1;
                    } else {
                        return except_sections.indexOf(String(item.section.id)) == -1;
                    }
                }
                return false;
            }).length > 0;
        },

        roomNumberInputTitle() {
            var roomNumberTitle = this.$root.hotel.ext_data.properties.room_number.title;

            return roomNumberTitle
                ? this.$root.getLangText(roomNumberTitle)
                : this.$root.getKeyWord('room_number');
        }
    },
};

