<template>
  <div class="bg-hotel shadow-lg rounded-lg cursor-pointer" :class="[ $root.hotelCode == 'novotelresort' ? 'novotelresort-color' : '']" id="open-cart" @click="openCart">
    <div class="flex items-center">
      <div  class="flex-1"><div class="cart_product" v-text="$root.cart_total_quantity"></div></div>
      <div><div class="header_cart" v-text="$root.getKeyWord('send_to_order')"></div></div>
      <div  class="flex-1"><div class="price_cart" v-html="$root.showPrice($root.cart_total_price)"></div></div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../main';
export default {
  name: 'app_cart_button',
  methods: {
    openCart() {
      eventBus.$emit('cart');
    }
  },
}
</script>


<style>
  .novotelresort-color {
    background: #3c3c3c;
  }
</style>