<template>
    <div class="flex items-center justify-between my-1" :class="[template === 'small' ? 'w-20' : 'w-24']">
        <button @click="decrement" :class="buttonClass">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6"></path></svg>
        </button>
        <div class="placeholder-black text-center">{{ qty }}</div>
        <button @click="increment" :class="buttonClass">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path></svg>
        </button>
    </div>
</template>

<script>
export default {
    props: { 
        value: {
            type: Number,
            default: 1
        }, 

        min: {
            type: [String, Number],
            required: false,
        }, 

        max: {
            type: [String, Number],
            required: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        
        template: {
            type: String,
            default: null,
        }
    },

    computed: {
      qty: {
          get() {            
            return this.value;
          },

          set(val) {
            this.$emit('update:value', val);
          }
      },

      buttonClass() {
        return this.template == 'small' 
            ? 'w-4 h-4 flex items-center justify-center text-black focus:outline-none'
            : 'w-8 h-8 bg-gray-200 rounded-lg flex items-center justify-center text-black focus:outline-none';
      }
    },

    methods: {
        increment() {
            if (this.disabled)
                return;
                            
            if (this.max !== undefined && this.max !== null) {
                if (this.qty >= parseInt(this.max)) {
                    this.qty = parseInt(this.max);
                    return;
                }
            }
            this.qty++;
        },

        decrement() {
            if (this.min !== undefined && this.min !== null) {
                if (this.qty <= parseInt(this.min)) {
                    this.qty = parseInt(this.min);
                    return;
                }
            }

            if (this.qty == 0) {
                return false;
            }
            
            this.qty--;
        },
    }
}
</script>