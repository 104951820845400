<template>
    <div class="language">
        <a href="javascript:void(0)" class="flex" :class="{'items-center gap-[5px] bg-[#66666620] p-[5px] rounded-[20px] text-[12px] text-[#eeeeee]' : $root.template === 'video_template' || $root.template === 'slider_template'}" @click="openOptionsLanguages = !openOptionsLanguages" v-click-outside="hideLanguage">
            <span v-if="$root.template === 'video_template' || $root.template === 'slider_template'" class="text-black">{{$root.currentLanguage.toUpperCase()}}</span>
            <img class="w-6 h-6" v-if="$root.currentLanguage !== undefined && $root.currentLanguage.length > 0" :src="require(`@/assets/image//${modifyLanguageCode($root.currentLanguage)}.svg`)" alt="">
        </a>
        <div class="languages z-50 border-gray-400" v-if="$root.languages" v-show="openOptionsLanguages" :class="[$root.hotelCode === 'courtyardpolyana'|| $root.hotelCode === 'courtyardkazan' ? 'left-0 z-10' : 'right-0']">
            <a href="javascript:void(0)" v-for="item in $root.languages" :key="item.code" @click="selectLanguage(item.code)"><span>{{ item.code === 'il' ? 'HEB' : item.code }}</span><img class="w-6 h-6 ml-[5px]" :src="require(`@/assets/image//${modifyLanguageCode(item.code)}.svg`)" alt=""></a>
        </div>
    </div>
</template>
<script>
import { eventBus } from '../../../main';

export default {
    name: 'ArticleSelectLangNovotelTemplate',

    data() {
        return {
            openOptionsLanguages: false,
        }
    },

    methods: {
        selectLanguage(lang_code) {
            this.$root.currentLanguage = lang_code;
            this.openOptionsLanguages = false;

            eventBus.$emit('change-language');
        },

        hideLanguage() {
            this.openOptionsLanguages = false;
        },

        modifyLanguageCode(code) {
            if (this.$root.hotel.site == 'thecarltonmoscow.2roomz.com' && code == 'ar') {
                return 'ar2';
            } else if (this.$root.hotel.site == 'ibisstylessarajevo.dgstay.com' && code == 'ar') {
                return 'ar3';
            }
            return code;
        },
    }
}
</script>
<style scoped>

</style>