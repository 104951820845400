<template>
    <div class="flex gap-2 px-2 py-2 w-full">
        <div class="flex items-center gap-3">
                <a-upload
                    name="file"
                    :multiple="false"
                    @change="handleChangeFile"
                    :show-upload-list="false"
                    :action="actionUploadUrl"
                >
                <button class="text-gray-500 hover:text-black mt-1" :style="{color: $root.hotel.color}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 md:w-8 md:h-8 fill-current" viewBox="0 0 512 512"><path d="M216.08 192v143.85a40.08 40.08 0 0080.15 0l.13-188.55a67.94 67.94 0 10-135.87 0v189.82a95.51 95.51 0 10191 0V159.74" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
                </button>
            </a-upload>
        </div>       
        <div class="bg-gray-100 rounded-2xl p-1 w-full flex gap-x-4 items-end relative">
            <textarea 
                v-model="s_content" 
                rows="1" 
                id="chat-input-message"
                ref="myTextarea"
                @keydown.enter.exact.prevent
                @keyup.enter="handleEnterKey"
                @input="adjustTextareaHeight"
                class="bg-transparent w-full px-2 py-1 md:py-2 resize-none rounded-lg text-gray-900 text-sm focus:outline-none placeholder-gray-400" :placeholder="placeHolder">
            </textarea>                       
        </div>      
        <div>                
            <button @click="sendMessage" class="block w-full h-full rounded-full focus:outline-none hover:text-blue-600 text-sm" :style="{color: $root.hotel.color}">   
                <svg v-if="s_loading" class="w-8 h-8 fill-current" viewBox="0 0 100 100" enable-background="new 0 0 0 0">
                    <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform 
                        attributeName="transform" 
                        attributeType="XML" 
                        type="rotate"
                        dur="1s" 
                        from="0 50 50"
                        to="360 50 50" 
                        repeatCount="indefinite" />
                    </path>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 md:w-7 md:h-7 fill-current"  viewBox="0 0 512 512"><path d="M16 464l480-208L16 48v160l320 48-320 48z"/></svg>
            </button>
        </div>
    </div> 
</template>

<script>
import Loading from '../../components/article/ArticleLoading.vue';

export default {
    props: ['chat', 'content', 'loading'],

    components: {Loading},

    computed: {
        s_content: {
            get () {
                return this.content
            },
            set (val) {
                this.$emit('update:content', val)
            }
        },

        s_loading: {
            get () {
                return this.loading
            },
            set (val) {
                this.$emit('update:loading', val)
            }
        },

        actionUploadUrl() {
            return process.env.VUE_APP_API_URL + 'public/chat/' + this.chat.uuid + '/files';
        },

        placeHolder() {
            return this.$root.getLangText({'ru': 'Отправить сообщение...', 'en': "Type here..."});
        }
    },

    data() {
        return {
            visibleEmoji: false,
            visibleDynamicParametrs: false,
    
            loading_file_upload: false,
        }
    },

    created() {
        if ('visualViewport' in window) {
                            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
        window.visualViewport.addEventListener('resize', function (event) {
            if (!document.querySelector('.vm--modal'))
                return;
            
            document.querySelector('.vm--modal').style.top = '0'; 
            document.querySelector('.vm--modal').style.height =  Math.round(vh) + 'px';        
            document.querySelector('.vm--modal').style.minHeight =  Math.round(vh) + 'px'; 
            if (
            (event.target.height * event.target.scale) / window.screen.height <
            VIEWPORT_VS_CLIENT_HEIGHT_RATIO
            ) {
                document.querySelector('.vm--modal').style.height =  Math.round(event.target.height) + 'px'; 
                document.querySelector('.vm--modal').style.minHeight =  Math.round(event.target.height) + 'px'; 
                document.querySelector('.vm--modal').style.top =  Math.round(vh - event.target.height) + 'px'; 
                
            } else {
                
            }
        });
        }
    },

    methods: {
        addEmoji(emoji) {
            this.s_content += emoji.native;
            this.visibleEmoji = false;
        },

        sendMessage() {
            this.$emit('send-message');
        },

        handleChangeFile(info) {           
            if (info.file.status == 'uploading') {
                this.s_loading = true;
            }

            if (info.file.status === 'done') {
                //this.loading_file_upload = false;
                this.$emit('send-file-message', info.file.response.data);
            } else if (info.file.status === 'error') {
                this.s_loading = false;
            }

            console.log('uploading');
        },        

        addDynamicParametrToContent(param) {
            this.s_content += '{{'+ param+ '}}';
            this.visibleDynamicParametrs = false;
        },

        adjustTextareaHeight() {
            const textarea = document.querySelector('#chat-input-message');
            textarea.style.height = 'auto'; // Reset the height to auto to recalculate properly
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
        },         

        handleEnterKey(event) {
            if (!this.isMobileDevice()) {                
                event.preventDefault(); // Prevent default Enter key behavior on desktop
                this.sendMessage();
                // Handle your custom logic here
            } else {
                this.s_content += "\n";
                this.adjustTextareaHeight();
            }
        },

        isMobileDevice() {
            const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            return mobileRegex.test(navigator.userAgent);
        },        
    }
}
</script>