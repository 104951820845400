<template>
    <div v-if="fetchedHotel && !loading">
        <app-hotel-bottom-navigation v-if="hotelBottomNavigationMenu" :navigation-menu="hotelBottomNavigationMenu"></app-hotel-bottom-navigation>
        <app-bottom-navigation v-else></app-bottom-navigation>
    </div>
</template>
<script>
import AppBottomNavigation from './AppBottomNavigation.vue';
import AppHotelBottomNavigation from './AppHotelBottomNavigation.vue';
import { eventBus } from '../../../main';
export default {
    components: { AppBottomNavigation, AppHotelBottomNavigation},

    data() {
        return {
            fetchedHotel: null,
            loading: false,
            hotelBottomNavigationMenu: null,
            section: null
        }
    },

    watch: {
        '$root.hotel': function(newHotel) {
            if (newHotel) {                
                var newPath = window.location.pathname;
                if (!this.fetchedHotel && !this.loading) {
                    this.fetch(newPath);
                }
            }
        }
    },

    created() {
        eventBus.$on('update-bottom-navigation-menu', data => {
            var newPath = data.newPath ? data.newPath : window.location.pathname;
            return this.fetch(newPath);
        });
    },

    methods: {
        // get bottom navigation from hotel
        fetch(currentUrl = null) {
            this.loading = true;
            var sectionId = this.getSectionId(currentUrl);
            
            this.$http.get(process.env.VUE_APP_API_URL + 'public/hotels/' + this.$root.hotel.uuid + '/bottom-navigation-menu', {
                params: {
                    section_id: sectionId,
                    path: currentUrl,
                }
            })
                .then(({data}) => {
                    this.loading = false;
                    this.fetchedHotel = true;
                    this.hotelBottomNavigationMenu = data;
                })
        },

        async getSection() {
            if (this.$root.hotel === undefined || this.$root.hotel.length == 0) {
                await this.$root.getHotel();
            }

            var sectionId = this.getSectionId(currentUrl);

            var url = process.env.VUE_APP_API_URL + 'hotels/' + this.$root.hotelCode + '/products/' + sectionId;
            await this.$http.get(url)
                .then(response => {
                    this.section = response.data.data.section;
                });
        },

        getSectionId(url = null) {
            const currentUrl = url ? url : window.location.pathname;

            // Define a regular expression pattern for valid URLs with an optional trailing slash
            const urlPattern = /^(\/sections\/|\/products\/)\d+\/?$/;

            // Check if the URL matches the pattern
            if (urlPattern.test(currentUrl)) {
                // Normalize the URL by removing any trailing slash
                const normalizedUrl = currentUrl.replace(/\/$/, '');

                // Extract the last part of the URL as the ID
                const parts = normalizedUrl.split('/');
                return parts[parts.length - 1];
            } else {
                return null;
            }           
        }
    }
}
</script>