<template>
    <div class="fixed left-1/2 -translate-x-1/2 max-w-[450px] md:max-w-[600px] top-0 z-40 w-full">
        <div class="flex justify-between p-4 items-center" id="header-fly">
            <div>
                <a class="w-10 h-10 text-black bg-white/70 focus:outline-none rounded-full text-sm p-2.5 text-center inline-flex items-center shadow"
                   id="homeBackButton" @click="backButton" v-if="$route.path != '/' && $route.path != '/home' && showBackButton">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" style="fill: #000;" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
                    </svg>
                </a>
            </div>
            <div id="header-title" class="font-medium text-lg hidden">
                {{ this.$root.title ? $root.getLangText(this.$root.title) : '' }}
            </div>

            <button type="button" id="btn-select-language"
                    class="w-10 h-10 text-black bg-white/70 focus:outline-none rounded-full text-sm p-2.5 text-center inline-flex items-center shadow">
                <select-language></select-language>
            </button>
        </div>
    </div>
</template>

<script>
import selectLanguage from './ArticleSelectLang';

export default {
    name: 'AppHeader',
    components: {selectLanguage},

    data() {
        return {}
    },

    methods: {
        backButton() {
            window.scrollTo(0, 0);

            var headerFly = document.querySelector('#header-fly');
			var headerFlyBackButton = document.querySelector('#homeBackButton');
			var headerFlySelectLanguageButton = document.querySelector('#btn-select-language');
			var headerFlyTitle = document.querySelector('#header-title');
            
			headerFly.classList.remove('bg-white');
			headerFly.classList.remove('p-2');
			headerFly.classList.add('p-4');
			headerFlyBackButton.classList.add('shadow');
			headerFlySelectLanguageButton.classList.add('shadow');
			headerFlyTitle.classList.add('hidden');

            localStorage.getItem('prev_route') === '/' ? this.$router.push({path: '/home'}) : this.$router.go(-1);
        }
    },

    computed: {
        showBackButton() {
            return true;
        }
    }
}
</script>
