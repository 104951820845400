<template>
<div>
    <div v-if="order">
        <div class="rounded mt-2 relative" style="background: #f6f7fb; min-height: 290px;">
            <div class="px-4 py-3 border-b" v-if="(order.ostatus_id == 'approved' || order.ostatus_id == 'accepted') && order.expected_timeleft">
                <article-order-timer :expected-timeleft="order.expected_timeleft"></article-order-timer>
            </div>
            <div v-if="order.integration_payment !== null" class="mb-2 border-b pb-2">
                <div id="alert-4" class="flex rounded-lg" role="alert" v-if="order.integration_payment.status_id == 'goes' && order.integration_payment.extra.payment_link !== undefined && order.integration_payment.extra.payment_link !== ''">
                    <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div class="ml-2 text-sm font-medium text-yellow-500">
                        {{ $root.getKeyWord('order_payment_pending') }}, {{ $root.getKeyWord('order_pay_click_link') }} <a :href="order.integration_payment.extra.payment_link" target="_blank" class="font-semibold underline text-blue-500">{{ $root.getKeyWord('order_online_pay') }}</a>
                    </div>
                </div>
                <div id="alert-3" class="flex rounded-lg dark:bg-green-200" role="alert" v-if="order.integration_payment.status_id == 'done'">
                    <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-green-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div class="ml-2 text-sm font-medium text-green-700">
                        {{ $root.getKeyWord('order_payment_paid') }}
                    </div>
                </div>    
                <div id="alert-3" class="flex rounded-lg dark:bg-green-200" role="alert" v-if="order.integration_payment.status_id == 'error'">
                    <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-green-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <div class="ml-2 text-sm font-medium text-green-700">
                        {{ $root.getKeyWord('order_payment_error') }}
                    </div>
                </div>                         
            </div>
            <div class="px-4 py-4">   
                <div class="flex justify-between items-center">
                    <div class="flex">                          
                        <span class="mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" v-if="order.ostatus_id == 'done'" viewBox="0 0 512 512" class="fill-current text-green-600 w-6 h-6"><path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm106.5 150.5L228.8 332.8h-.1c-1.7 1.7-6.3 5.5-11.6 5.5-3.8 0-8.1-2.1-11.7-5.7l-56-56c-1.6-1.6-1.6-4.1 0-5.7l17.8-17.8c.8-.8 1.8-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l44.4 44.4 122-122.9c.8-.8 1.8-1.2 2.8-1.2 1.1 0 2.1.4 2.8 1.2l17.5 18.1c1.8 1.7 1.8 4.2.2 5.8z"></path></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" v-if="order.ostatus_id == 'cancelled' || order.ostatus_id == 'expired'" class="fill-current text-red-600 w-6 h-6" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"/></svg>
                        </span>
                        <div class="font-medium text-lg">#{{ order.num }}</div>
                    </div>
                    <div class="text-xs text-gray-600 leading-tight">{{ $root.formatDateFromNow(order.created_at) }}</div>
                </div>
                <div class="mt-2">
                    <div class="text-sm flex items-baseline text-gray-800" v-if="order.number_room !== null">
                        <span>{{ $root.getKeyWord('room_number') }}:</span>
                        <span class="mx-1 font-medium text-black"> {{ order.number_room }}</span>
                    </div>
                    <div class="text-sm flex items-baseline text-gray-800" v-if="order.final_sum > 0 && order.order_payment && order.order_payment !== null">
                        <span>{{ $root.getKeyWord('payment') }}:</span>
                        <span class="mx-1 font-medium text-black">{{ $root.getLangObject(order.order_payment.title) }}</span>
                    </div>
                    <div class="text-sm flex items-baseline text-gray-800" v-if="order.comment.length > 0">
                        <span>{{ $root.getKeyWord('comments') }}:</span>
                        <span class="mx-1 font-medium text-black">{{ order.comment }}</span>
                    </div>
                </div>
                <div class="border-b">
                    <div v-for="(product, index) in order.products" :key="product.id" class="mt-2 py-2">
                        <div class="flex justify-between text-sm">
                            <div>
                                <span class="mr-1">{{ ++index }}.</span>
                                <span class="break-words">{{ $root.getLangText(product.product.title) }}</span>

                                <div v-if="product.properties.length > 0" class="ml-4">                                
                                    <div class="text-xs text-gray-600" v-for="property in product.properties" :key="property.id">
                                        <span class="font-medium text-gray-700">{{ $root.getLangText(property.property.title) }}: </span>                                    
                                        <span>{{ property.value }}</span>
                                        <span class="font-medium text-gray-700" v-if="property.quantity !== undefined && property.quantity > 1"> x {{ property.quantity }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-shrink-0" v-show="product.price > 0">
                                <span v-if="product.quantity > 1">{{ product.quantity }} x </span><span v-html="$root.showPrice(product.total_price)"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="py-2  text-sm">
                    <div v-for="property in order.properties" :key="property.id" v-if="property.total_price > 0">
                        <div class="flex items-baseline justify-between w-full">
                            <div>
                                <div>
                                    <span v-if="property.quantity > 1">{{property.quantity}}{{ $root.getKeyWord('telegram.txt_order_pc') }} - </span>
                                    <span>{{ $root.getLangText(property.property.title) }}</span>
                                </div>
                            </div>
                            <div>
                                <span v-if="property.total_price > 0" v-html="$root.showPrice(property.total_price)"></span>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between" v-if="order.delivery_price > 0">
                        <div class="text-gray-800">{{ $root.getKeyWord('discount_price') }}</div>
                        <div class="text-sm" v-html="$root.showPrice(order.delivery_price)"></div>
                    </div>
                    <div class="flex justify-between" v-if="order.final_sum > 0">
                        <div class="text-gray-800">{{ $root.getKeyWord('total') }}</div>
                        <div class="text-sm" v-html="$root.showPrice(order.final_sum)"></div>
                    </div>
                </div>

                <div class="absolute bg-black  rounded-lg left-0 top-0 w-full h-full" style="backdrop-filter: blur(5px); background: rgba(0, 0, 0, .4)" :class="{ hidden: !display_cancel_popup }">
                    <a href="javascript:void(0)" class="hover:bg-gray-200 absolute right-0 m-2" @click="display_cancel_popup = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="32" width="32" class="fill-current text-white hover:text-black rounded-full"><title>Close</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"></path></svg>
                    </a>
                    <div class="flex flex justify-center align-center">                    
                        <div class="flex flex-col py-6 px-2">
                            <div class="text-base sm:text-xl text-center text-white py-1">{{ $root.getKeyWord('order_cancel__reason') }}</div>
                            <ul class="font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                <li class="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                                    <div class="flex items-center">
                                        <input id="order_cancel__mistake" type="radio" v-model="picked_reason" value="order_cancel__mistake" class="w-4 h-4 flex-none border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" checked>
                                        <label for="order_cancel__mistake" class="block ml-2 font-medium text-gray-900 dark:text-gray-300 text-sm sm:text-base">{{ $root.getKeyWord('order_cancel__mistake') }}</label>
                                    </div>
                                </li>
                                <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">
                                    <div class="flex items-center">
                                        <input id="order_cancel__change" type="radio" v-model="picked_reason" value="order_cancel__change" class="w-4 h-4 flex-none border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" checked>
                                        <label for="order_cancel__change" class="block ml-2 font-medium text-gray-900 dark:text-gray-300 text-sm sm:text-base">{{ $root.getKeyWord('order_cancel__change') }}</label>
                                    </div>
                                </li>
                                <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">
                                    <div class="flex items-center">
                                        <input id="order_cancel__payment" type="radio" v-model="picked_reason" value="order_cancel__payment" class="w-4 h-4 flex-none border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" checked>
                                        <label for="order_cancel__payment" class="block ml-2 font-medium text-gray-900 dark:text-gray-300 text-sm sm:text-base">{{ $root.getKeyWord('order_cancel__payment') }}</label>
                                    </div>
                                </li>
                                <li class="w-full px-4 py-2 dark:border-gray-600">
                                    <div class="flex items-center">
                                        <input id="order_cancel__other" type="radio" v-model="picked_reason" value="order_cancel__other"  class="w-4 h-4 flex-none border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" checked>
                                        <label for="order_cancel__other" class="block ml-2 font-medium text-gray-900 dark:text-gray-300 text-sm sm:text-base">{{ $root.getKeyWord('order_cancel__other') }}</label>
                                    </div>
                                </li>
                            </ul>
                            <div class="flex justify-center mt-2" v-if="!loading">
                                <a href="javascript:void(0)" @click="cancelOrder(order)" class="bg-white px-4 py-2 rounded-full text-sm sm:text-base text-black">{{ $root.getKeyWord('ready_btn') }}</a>
                            </div>
                            <div class="flex justify-center" v-else>
                                <svg version="1.1" style="height: 100px; width: 100px;">
                                    <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                        <animateTransform 
                                            attributeName="transform" 
                                            attributeType="XML" 
                                            type="rotate"
                                            dur="1s" 
                                            from="0 50 50"
                                            to="360 50 50" 
                                            repeatCount="indefinite" />
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-sm pt-1 flex items-center text-gray-800" v-if="order.cancel_reason_id !== null && order.cancel_reason_id.length > 0">
                    <span>{{$root.getKeyWord("order_cancel__reason")}}:</span>
                    <span class="mx-1 font-medium text-black"> {{ $root.getKeyWord(order.cancel_reason_id)  }}</span>
                </div>            
                <div class="flex justify-center mt-2" @click="display_cancel_popup = true" v-if="displayCancelButton()">
                    <a href="javascript:void(0)" class="text-xs text-red-600 px-4 py-1 border rounded-lg hover:text-gray-700">{{ $root.getKeyWord('cancel_order') }}</a>
                </div>
                <div v-if="order.note_kit != null && order.note_kit.notes != null && order.note_kit.notes.length > 0" class="mt-2">
                  <div class="text-gray-600 flex gap-1 items-center justify-center mb-2">
                    <span>{{ $root.getLangText({ru: 'Комментарии к заказу', en: 'Comments on the order'}) }}</span>
                  </div>
                  <div v-for="comment in order.note_kit.notes" class="flex items-baseline justify-between w-full py-2 mb-2 border bg-white px-4 rounded-lg">
                    <div class="leading-tight text-left font-medium">
                      <div class="mb-1">
                        <span>{{ comment.content }}</span>
                      </div>
                      <div class="flex justify-start gap-1">
                        <div
                            class="flex gap-1 items-center text-gray-400 text-xs font-medium my-1 py-0.5 rounded">
                          <span>{{ comment.created_at_tz|dateFormatTZ }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>    
</template>

<script>
import { eventBus } from '../../../main';
import ArticleOrderTimer from './ArticleOrderTimer';
export default {
    components: { ArticleOrderTimer },
     
    props: ['order', 'type'],
    
    data() {
        return {
            display_cancel_popup: false,
            picked_reason: 'order_cancel__mistake',
            loading: false,
        }
    },

    methods: {
        cancelOrder(order) {
            this.loading = true;
            this.$http.post(process.env.VUE_APP_API_URL + 'orders/guest/change-status', {
                uuid: order.uuid,
                token: 'token',
                hotel_id: order.hotel_id,
                language: this.$root.currentLanguage,
                reason: this.picked_reason,
            }).then(response => {
                this.loading = false;
                //this.$parent.$parent.$parent.fetch();
                eventBus.$emit('get-orders');
                this.display_cancel_popup = false;
            }).catch(errors => {
                this.loading = false;
                //this.$parent.$parent.$parent.fetch();
                eventBus.$emit('get-orders');
                this.display_cancel_popup = false;
            });
        },

        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        displayCancelButton() {
            return this.$parent.orderType == 'draft';
        }
    }
}
</script>