<template>
  <transition name="modal" v-if="visible">
    <div class="wrapper_popup" id="order_confirmation">
      <div class="order_confirmation pb-4">
        <div class="header_bg"></div>
        <div class="img_order_confirmation">
          <img :src="$root.logo" class="w-48">
        </div>
        <div class="text_order_confirmation text-center">
          <div v-html="text_accepted"></div>
        </div>
        <div class="container px-4 mt-16">
          <div class="mb-4 relative" @click="sendToPay" v-if="payment_url">
            <button class="border border-color-hotel border-2 px-4 h-[44px] rounded-lg w-full flex justify-center gap-2 text-lg items-center base-color">
              <svg xmlns="http://www.w3.org/2000/svg" class="animate-bounce w-6 h-6 fill-current base-color " viewBox="0 0 512 512"><title>Arrow Forward</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"/></svg>
              {{ $root.getKeyWord('order_online_pay') }} <span v-html="$root.showPrice(total_price)"></span>
            </button>
          </div>
          
          <button class="default_green_btn" v-if="displayViewOrdersButton" @click="openMyOrders" v-text="$root.getKeyWord('view_order')"></button>
          
          <div class="text-center my-4" v-if="parent_section.is_hidden !== true">
            <a href="#" class="text-gray-600 underline text-center uppercase " @click="close" v-text="$root.getKeyWord('home')"></a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  import { eventBus } from '../../main';
  
  export default {
    name: 'order_success_splash',

    data() {
      return {
        visible: false,
        client_name: '',
        section: null,
        parent_section: null,
        payment_url: null,
        total_price: 0,
        text_accepted: '',
      }
    },

    created() {
        eventBus.$on('order-success-splash', data => {
            this.section = data.section;
            this.parent_section = data.parent_section;  
            this.client_name = data.client_name;          
            this.payment_url = data.payment_url;
            this.total_price = data.total_price;
            this.visible = true;

            this.setTextAcceptedOrder();
        });
    },    

    computed: {
      displayViewOrdersButton() {
        if (this.section == null) 
          return true;
        
        return !this.section.hide_view_orders_button;
      }
    },

    methods: {
      close() {
        this.visible = false;
        window.location.href = '/'
      },

      sendToPay() {
        window.open(this.payment_url, '_blank').focus();
        this.visible = false;
        eventBus.$emit('toggle-my-orders');
      },

      openMyOrders() {
        this.visible = false;
        eventBus.$emit('toggle-my-orders');
      },

      setTextAcceptedOrder() {
        let result = '';

        var text_accepted_section = this.getTextAcceptedOrder(this.section.text_accepted_order);
        var text_accepted_parent_section = this.getTextAcceptedOrder(this.parent_section.text_accepted_order);
        var text_accepted_hotel =  this.getTextAcceptedOrder(this.$root.hotel.text_accepted_order);

        if (text_accepted_section)
          result = text_accepted_section;
        else if (text_accepted_parent_section)
          result = text_accepted_parent_section;
        else if (text_accepted_hotel) 
          result = text_accepted_hotel
        else 
          result = this.$root.getKeyWord('accept_order');

        this.text_accepted = result;

        // set name
        if (result) {
          var name =  (localStorage.getItem('fs_name') != undefined) ? localStorage.getItem('fs_name') : ''
          this.text_accepted = result.replace("#name#", name);
        }
      },

      getTextAcceptedOrder(text) {
        console.log(text);
          return text != "" && Object.keys(text).length > 0 && 
                        Object.values(text).filter(item => item !== null && item !== '').length > 0
                 // ? this.$root.stripTags(this.$root.getLangText(text))
                 ? this.$root.getLangText(text)
                  : '';
      },
    }
  }
</script>

<style>
.animate-ping {
    -webkit-animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite !important;
    animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}
</style>

