import { render, staticRenderFns } from "./AppHotelBottomNavigation.vue?vue&type=template&id=72a23b00&"
import script from "./AppHotelBottomNavigation.vue?vue&type=script&lang=js&"
export * from "./AppHotelBottomNavigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports