<template>
    <div>
        <div  v-if="recommended_products.length > 0"  class="px-2 py-4">
            <div v-text="$root.getKeyWord('recommended_title')" class="text-sm font-medium px-1"></div>
            <div class="flex overflow-x-auto scroll" v-if="isMobileDevice">
                <div @click="addRecommendedProduct(item)" 
                      v-for="(item, index) in recommended_products" :key="index" 
                      class="flex flex-col justify-between shadow rounded my-2 mx-1 p-1 w-28 flex-shrink-0">
                    <div>
                        <div class="w-26 h-20 rounded bg-gray-100" v-if="showImage">
                            <img v-lazy="getLasyImage($root.getImage(item.image, item.image_path !== undefined ? item.image_path : null))" class="h-full w-full object-cover rounded">
                        </div>
                        <div v-text="$root.getLangText(item.title)" class="text-xxs py-1"></div>
                    </div>
                    <div class="font-medium text-sm text-gray-600 py-2 text-center" v-html="$root.showPrice(item.price, item.is_price_from)"></div>
                </div>
            </div>

            <div class="relative scroll" v-if="!isMobileDevice">
                <div @click="manuallyLeft()" 
                      class="scroll-btn cursor-pointer absolute w-10 h-10 flex items-center justify-center z-40 rounded-full text-transparent bg-transparent border border-transparent"
                      v-bind:class="{'arrow-show': displayBtnManullyLeft, 'arrow-hide': !displayBtnManullyLeft}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /></svg>
                </div>
                <div class="overflow-scroll scroll relative" id="scroll-recomandations" v-dragscroll.x="true"
                     v-on:dragscrollstart="startDragScroll"
                     v-on:dragscrollend="endDragScroll">

                    <div class="flex relative" id="scroll_recomandations_flex">
                        <div @click="addRecommendedProduct(item)" v-for="(item, index) in recommended_products" :key="index" class="scroll-recomendation-item flex flex-col justify-between shadow rounded my-2 mx-1 p-1 w-28 flex-shrink-0">
                            <div>
                                <div class="w-26 h-20 rounded bg-gray-100" v-if="showImage">
                                    <img v-lazy="getLasyImage($root.getImage(item.image, item.image_path !== undefined ? item.image_path : null))" class="h-full w-full object-cover rounded">
                                </div>
                                <div v-text="$root.getLangText(item.title)" class="text-xxs py-1"></div>
                            </div>
                            <div class="font-medium text-sm text-gray-600 py-2 text-center" v-html="$root.showPrice(item.price, item.is_price_from)"></div>
                        </div>
                    </div>

                </div>
                <div @click="manuallyRight()"  
                      class="scroll-btn cursor-pointer right-0 top-0 absolute w-10 h-10 flex items-center justify-center z-40 rounded-full text-transparent bg-transparent border border-transparent"
                      v-bind:class="{'arrow-show': displayBtnManullyRight, 'arrow-hide': !displayBtnManullyRight}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { dragscroll } from 'vue-dragscroll';
    import { eventBus } from '../../main';
    import {AdultsOnlyProductWarning} from '@/components/mixins/AdultsOnlyProductWarning';

    export default {
        props: ['products'],
        mixins: [AdultsOnlyProductWarning],
        data() {
            return {
                recommended_products: [],
                displayBtnManullyLeft: false,
                displayBtnManullyRight: true,
                isMobileDevice: false,
                showImage: false
            }
        },

        directives: {
            dragscroll,
        },

        created() {
            this.getRecommendedProducts();
            this.isMobileDevice = this.$root.isMobile();
        },

        mounted(){
            if (!this.isMobileDevice) {
                let scrolledContentWidth = 0;
                let list = document.querySelectorAll(".scroll-recomendation-item");
                    
                Array.from(list).map(elem => {
                    scrolledContentWidth += Number(elem.clientWidth);
                });

                if (document.querySelector('#scroll-recomandations')) {
                    this.displayBtnManullyRight = Number(document.querySelector('#scroll-recomandations').offsetWidth) < scrolledContentWidth;
                } else {
                    this.displayBtnManullyRight = false;
                }
            }
        
        },

        methods: {
            startDragScroll() {
                let $this = this;
                setTimeout(function () {
                    $this.click = false;
                }, 100);
            },

            endDragScroll() {
                let $this = this;
                let scrolledContentWidth = this.calculateScrolledContentWidth();
                this.displayBtnManullyLeft = (Number(document.querySelector('#scroll-recomandations').scrollLeft) > 0);
                this.displayBtnManullyRight = (Number(document.querySelector('#scroll-recomandations').scrollLeft) < (Number(scrolledContentWidth) - Number(document.querySelector('#scroll-recomandations').offsetWidth)));
                
                setTimeout(function () {
                    $this.click = true;
                }, 100);
            },

            checkInView(el) {
                //Get container properties
                var top = el.offsetTop - 130;
                var left = el.offsetLeft;
                var width = el.offsetWidth;
                var height = el.offsetHeight;

                while (el.offsetParent) {
                    el = el.offsetParent;
                    top += el.offsetTop;
                    left += el.offsetLeft;
                }

                return (
                        top < (window.pageYOffset + window.innerHeight) &&
                        left < (window.pageXOffset + window.innerWidth) &&
                        (top + height) > window.pageYOffset &&
                        (left + width) > window.pageXOffset
                        );
            },

            manuallyLeft() {
                document.querySelector('#scroll-recomandations').scrollLeft =
                        document.querySelector('#scroll-recomandations').scrollLeft - 50;

                if (document.querySelector('#scroll-recomandations').scrollLeft <= 0) {
                    this.displayBtnManullyLeft = false;
                }

                this.displayBtnManullyRight = true;
            },
            
            calculateScrolledContentWidth(){
                let list = document.querySelectorAll(".scroll-recomendation-item");
                let scrolledContentWidth = 0;
                Array.from(list).map(elem => {
                    scrolledContentWidth += Number(elem.clientWidth);
                });
                return scrolledContentWidth;
            },

            manuallyRight() {
                let scrolledContentWidth = this.calculateScrolledContentWidth();
                if (document.querySelector('#scroll-recomandations').scrollLeft >= Number(scrolledContentWidth) - Number(document.querySelector('#scroll-recomandations').offsetWidth)) {
                    this.displayBtnManullyRight = false;
                }
                document.querySelector('#scroll-recomandations').scrollLeft += 50;
                this.displayBtnManullyLeft = true;
            },

            getRecommendedProducts() {
                let selectedProductIds = this.products.reduce(function(queryString, productData){
                    return String(queryString) + String('product_id[]=' + productData.id + '&');
                }, '?');
                
                this.$http.get(process.env.VUE_APP_API_URL + 'hotels/' + this.$root.hotelCode + '/products-recommendations' + selectedProductIds,)
                    .then(response => {
                        this.recommended_products = response.data.data.recommended_products;
                        this.getPhotos();
                    });
            },


            getPhotos() {
                this.recommended_products.filter(element => element.image_path !== undefined && element.image_path !== '').length > 0 ? this.showImage = true : this.showImage = false;
            },


            getLasyImage(src) {
                let lazyImgObj = {
                    src: src,
                    error: require(`@/assets/image/splash.png`),
                    loading: require(`@/assets/image/splash.png`)
                };

                return lazyImgObj;
            },

            addRecommendedProduct(item) {  
                var coItem = JSON.parse(JSON.stringify(item));
                var section = coItem.section;
                delete coItem.section;

                coItem.image = coItem.image_path;
                
                let is_adult_section = (localStorage.getItem('is_adult') === undefined || localStorage.getItem('is_adult') === null) && item.section.for_adult !== null && item.section.for_adult;
                
                if (is_adult_section) {
                    this.$modal.show('modal-extra', {item: item, section_id: null});
                    return false;
                } else {
                    if(this.checkIsAdultProduct(item)){
                        return false;
                    }
                }

                eventBus.$emit('product-cart', {
                    product: JSON.stringify(coItem),
                    section: section,
                    parent_section: section.parent,
                    from_recommendation: true,
                    action: {
                        type: 'create',
                        index_for_update: null,
                    }            
                });
            },

            setRecommendedProduct() {
                // test
                return true;
            }
        }
    }

</script>

<style>
    .w-21 {
        width: 5.500rem;
    }

    .text-xxs {
        font-size: 0.5rem;
    }

    .scroll::-webkit-scrollbar {
        display: none;
    }
    .overflow-scroll{
        overflow-y: hidden !important;
        overflow-x: auto !important;
    }

</style>
<style scoped="">
    .scroll{
        position: relative;
    }

    .scroll:hover .scroll-btn {
        background: #ffffff;
        border: 1px solid #cbd5e0;
        display: flex;
        color: #4a5568;
        top: 50%;
        margin-top: -1.25rem;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
    .arrow-show{
        opacity: 1;
    }
    .arrow-hide{
        opacity: 0;
    }
</style>