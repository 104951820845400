<template>
  <div>
    <div class="flex form_group relative">
      <img @click="$modal.show('modal-select-country')"
           v-bind:src="require(`@/assets/flags/` + activeNum.code + `.svg`)" alt=""
           class="object-contain absolute p-1 left-1 top-0.5 w-8 h-8" id="img_country"/>
      <the-mask :mask="mask.replaceAll('_', '#')" :tokens="customTokens" :masked="true" v-bind:placeholder="mask"
                v-model="phone" style="width: 100%; border-radius: 0px 8px 8px 0px; padding-left: 45px;" name="phone"
                id="number" type="tel"></the-mask>
    </div>

    <modal name="modal-select-country" :width="350" :height="'auto'" scrollable>
      <div class="text-center bg-white py-4 px-2 relative">
        <div class="right-0 top-0 p-2 absolute">
          <button @click="$modal.hide('modal-select-country')" class="text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="flex items-center  w-full px-2 mt-6 bg-white">
          <div class="relative w-full">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor"
                   viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input type="search" id="search" @input="searchCountry($event)"
                   class="block px-4 py-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                   style="padding-left: 35px;" placeholder="" required>
          </div>
        </div>
        <div class="pt-4 px-2 h-[50vh] overflow-auto">
          <ul style="padding:0">
            <li v-for="item in numbers" v-bind:key="item.id" @click="selectCountry(item, true)"
                class="flex items-center justify-between py-4 px-2 border-b hover:bg-gray-100 cusor-pointer">
              <div class="flex items-center gap-2">
                <img :src="getFlag(item.code)" class="w-5 h-5" alt=""/>
                <span class="text-left">{{ item.name }}</span>
              </div>
              <span class="text-[#001345] font-bold text-left">{{ item.dial_code }}</span>
            </li>
          </ul>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import NUMBERS from "./utils/numbers.json";
import COUNTRIES from "./utils/timezones-per-country.json";

export default {
  name: "AppInputPhoneNumber",

  props: ['phoneNumber', 'propertyId'],

  data() {
    return {
      customTokens: {
        Y: {pattern: /[0-9]/},
        "#": {pattern: /\d/},
        X: {pattern: /[0-9a-zA-Z]/},
        S: {pattern: /[a-zA-Z]/},
        A: {pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase()},
        a: {pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase()},
        "!": {escape: true}
      },
      numbers: NUMBERS,
      countries: COUNTRIES,
      activeNum: {
        code: "US",
        mask: localStorage.getItem('phone-number-mask') !== null ? localStorage.getItem('phone-number-mask') : "+1___-___-____",
      },
      mask: localStorage.getItem('phone-number-mask') !== null ? localStorage.getItem('phone-number-mask') : "+1___-___-____",
    };
  },

  computed: {
    phone: {
      get() {
        return this.phoneNumber;
      },

      set(val) {
        this.$emit('update:phoneNumber', val);
      }
    },
  },
    watch: {
        phone(val) {
            this.$root.setPropertyToLocalStorage(this.propertyId, val);
        }
    },
  created() {
    this.initDetectCountry();
  },

  methods: {
    searchCountry(e) {
      const filteredNumbers = [];
      NUMBERS.filter((number) => {
        if (number.name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || number.dial_code.includes(e.target.value)) {
          filteredNumbers.push(number);
        }
      });
      this.numbers = filteredNumbers;
    },

    getFlag(code) {
      try {
        return require(`@/assets/flags/` + code + `.svg`)
      } catch (e) {
        return '';
      }
    },

    selectCountry(item, removePhone = false) {
      this.activeNum = item;
      if (removePhone)
        this.phone = '';

      this.mask = item.mask;
      this.mask = item.dial_code + ' ' + item.mask;

      localStorage.setItem('phone-number-mask', this.mask);
      this.$modal.hide('modal-select-country');
    },

    autoDetectCountry(item, removePhone = false) {
      this.activeNum = item;
      if (removePhone)
        this.phone = '';

      if (localStorage.getItem('phone-number-mask') !== null) {
        this.mask = localStorage.getItem('phone-number-mask');
        return;
      }

      this.mask = item.mask;
      this.mask = item.dial_code + ' ' + item.mask;

      this.$modal.hide('modal-select-country');
    },

    foundDialCode(phone_number) {
      for (var i = 0; i < phone_number.length - 1; i++) {
        var subphone = phone_number.substring(0, phone_number.length - i);
        var foundDialCode = this.numbers.filter(item => item.dial_code == subphone);

        if (foundDialCode.length > 0) {
          this.autoDetectCountry(foundDialCode[0]);
          return true;
          break;
        }
      }
    },

    initDetectCountry() {
      try {
        if (this.phoneNumber === undefined || this.phoneNumber === '' || this.phoneNumber === null) {
          var countryCode = "+1";
          if (this.$root.hotel.ext_data !== null && this.$root.hotel.ext_data.country_code !== undefined && this.$root.hotel.ext_data.country_code !== '' && this.$root.hotel.ext_data.country_code !== null) {
            countryCode = this.$root.hotel.ext_data.country_code;
          } else {
            if (process.env.VUE_APP_SERVER.toLowerCase() == 'ru') {
              countryCode = "+7";
            }

            if (process.env.VUE_APP_SERVER.toLowerCase() == 'eu') {
              countryCode = "+48";
            }

            if (process.env.VUE_APP_SERVER.toLowerCase() == 'uk') {
              countryCode = "+44 (0)";
            }

            if (process.env.VUE_APP_SERVER.toLowerCase() == 'ca') {
              countryCode = "+1___";
            }
          }
          if (countryCode.length > 0) {
            var foundDialCode = this.numbers.filter(item => item.dial_code == countryCode);
            if (foundDialCode.length > 0) {
              this.autoDetectCountry(foundDialCode[0], true);
            }
          }
        } else {
          this.foundDialCode(this.phoneNumber);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>