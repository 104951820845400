<template>
<div>
    <div class="language">
        <a href="javascript:void(0)" class="flex" @click="openOptionsLanguages = !openOptionsLanguages" v-click-outside="hideLanguage">
            <img class="w-6 h-6" v-if="$root.currentLanguage !== undefined && $root.currentLanguage.length > 0" :src="require(`@/assets/image//${modifyLanguageCode($root.currentLanguage)}.svg`)" alt="">            
        </a>
        <div class="languages z-50" v-if="$root.languages" v-show="openOptionsLanguages" :class="[$root.hotelCode === 'courtyardpolyana'|| $root.hotelCode === 'courtyardkazan' ? 'left-0 z-10' : 'right-0']">
            <a href="javascript:void(0)" v-for="item in $root.languages" :key="item.code" @click="selectLanguage(item.code)"><img class="w-6 h-6" :src="require(`@/assets/image//${modifyLanguageCode(item.code)}.svg`)" alt=""><span>{{ item.code === 'il' ? 'HEB' : item.code }}</span></a>
        </div>
    </div>
</div>
</template>

<script>
import { eventBus } from '../../../main';
export default {
  name: 'ArticleSelectLang',
  
  data() {
      return {
        openOptionsLanguages: false,
      }
  },

  methods: {
      selectLanguage(lang_code) {
          this.$root.currentLanguage = lang_code;
          this.openOptionsLanguages = false;

          eventBus.$emit('change-language');
      },

      hideLanguage() {
          this.openOptionsLanguages = false;
      },

      modifyLanguageCode(code) {
        if (this.$root.hotel.site == 'thecarltonmoscow.2roomz.com' && code == 'ar') {
            return 'ar2';
        } else if (this.$root.hotel.site == 'ibisstylessarajevo.dgstay.com' && code == 'ar') {
            return 'ar3';
        } 
        return code;
      },
  }
}
</script>