<template>
    <a-drawer wrapClassName="drawer-custom" placement="bottom"  :height="'89%'"  :closable="false" @close="onClose" :visible="visible">
        <div class="flex justify-end">
            <a href="javascript:void(0)" @click="visible = false" class="block hover:bg-gray-200 rounded-full p-1">
                <svg xmlns='http://www.w3.org/2000/svg' class='fill-current text-gray-800 rounded-full' viewBox='0 0 512 512'  height="32" width="32"><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg>
            </a>
        </div>
        <div v-if="!success">
            <div class="p-3">
            <div>
                <div class="text-gray-400">Resarvation number</div>
                <p>LY 8066</p>
            </div>
            <div class="flex items-center mt-4 justify-between">
                <div class="flex flex-col">
                    <div class="text-gray-400">Arrival</div>
                    <p>Wed 5 Oct</p>
                </div>
                <div class="flex flex-col">
                <button class="w-[20vw] border"></button>
                </div>
                <div class="flex flex-col">
                    <div class="text-gray-400">Departure</div>
                    <p>Wed 12 Oct</p>
                </div>
            </div>
            <div class="mt-4 border-b-2 border-b-gray-100">
                <label for="file">
                <div>Passport photo</div>
                <div class="text-gray-400 py-2">Upload</div>
                </label>
                <input type="file" id="file" class="hidden" />
            </div>
            <div class="pt-6">
                <p>Digital signature</p>
                <div class="border p-2">
                    <vueSignature ref="signature" :sigOption="option" :w="'100%'" :h="'100px'" :disabled="disabled"></vueSignature> 
                </div>
            </div>
            <div class="flex justify-end mt-2">
                <button class="px-5 py-2 rounded-[3rem] bg-gray-100" @click="clear" id="clear">Clear</button>
            </div>
            </div>
            <div class="flex justify-center py-8">
                <button class="px-5 py-2 rounded-[3rem] border border-black" @click="save">Check-in →</button>
            </div>
        </div>
        <div v-else>
            <div class="flex flex-col items-center justify-center">
                <div class="mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-green-500 fill-current" viewBox="0 0 512 512"><title>Checkmark Circle</title><path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z"/></svg>
                </div>
                <div>Online-in checkin is done</div>
            </div>
        </div>
    </a-drawer>    
</template>

<script>
import vueSignature from "vue-signature";
import moment from 'moment';

export default {
    name: 'AppBottomCart',

	components:{
		vueSignature
	},

	data() {
		return {
			option:{
				penColor:"rgb(0, 0, 0)",
				backgroundColor:"rgb(255,255,255)"
			},
			disabled:false,
			dataUrl:"https://avatars2.githubusercontent.com/u/17644818?s=460&v=4",
            visible: false,

            success: false,
            from_date: moment().format('Y-m-d'),
            to_date: moment().add('+3 days').format('Y-m-d'),
		};
	},

    created() {
        var online_checking = this.$root.getQueryVariable('online-checkin');
        if(online_checking) {
            this.visible = true;
        }
    },

    methods: {
        onClose() {
            this.visible = false;
        }, 
        clear(){
			this.$refs.signature.clear();
		},        
        save() {
            this.success = true;
        }
    }    
}
</script>
