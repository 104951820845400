<template>
  <div id="app" 
    @wheel="mainScroll($event)" 
    :style="cssProps"    
    :class="[$root.template, ($root.hotel.site == 'moon2.2roomz.com') ? 'bg-[#383838]' : '']" 
    :data-hotel-site="$root.hotel ? $root.hotel.site : ''"
  >
      <div :class="[$route.name == 'home' && ($root.template == 'video_template' || $root.template == 'slider_template') ? '' : 'wrapper']">
      <div :class="[$route.name == 'home' && ($root.template == 'video_template' || $root.template == 'slider_template') ? '' : 'container']">
        <app-announcements></app-announcements>
        <app-header v-if="($route.name == 'home' || $route.name == 'ar' || $route.name == 'ar-promo' || $route.name == 'booking') && ($root.template !== 'video_template' && $root.template !== 'slider_template')"></app-header>
        <app-fly-header v-if="$route.name !== 'home' && $route.name !== 'ar' && $route.name !== 'ar-promo' && $route.name !== 'booking' "></app-fly-header>  

        <router-view></router-view>
      </div>
    </div>
    <app-footer v-if="($root.template !== 'video_template' && $root.template !== 'slider_template')"></app-footer>

    <app-bottom-navigation v-if="$root.hotel && $root.hotel.site != 'gifts.dgstay.com' && $root.hotel.site != 'gifts.2roomz.com'"></app-bottom-navigation>
    <app-chat></app-chat>
    <app-cart></app-cart>
    <app-order-success-splash></app-order-success-splash>
    <app-product-cart></app-product-cart>
    
    <ArticleProductFastCart></ArticleProductFastCart>
    <div class="fixed bottom-0 left-1/2 transform -translate-x-1/2 translate-y-[-80px] sm:translate-x-[200px] translate-x-[100px]">
        <button @click="openChat()" v-if="$root.hotel.is_active_chat && $root.hotelCode === 'roomstbilisi'"  class="w-16 h-16 bg-red-500 rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="fill-current w-6 h-6 text-white">
                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"></path>
                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"></path>
            </svg>
        </button>
    </div>
      <app-agreement></app-agreement>
      <app-privacy-policy></app-privacy-policy>
  </div>
</template>

<script type="text/javascript">
  import AppHeader from './components/layout/header/AppHeader.vue'
  import AppFlyHeader from './components/layout/header/AppFlyHeader.vue'
  import AppFooter from './components/layout/AppFooter.vue'
  import AppCart from './components/article/AppCart.vue'
  import AppChat from './components/chat/index.vue'
  import AppProductCart from './pages/Product/ArticleProductCart.vue'
  import ArticleProductFastCart from './pages/Product/ArticleProductFastStore.vue'
  import AppOrderSuccessSplash from './components/article/AppOrderSuccessSplash';
  import AppBottomNavigation from './components/layout/bottom-navigation/index.vue';
  import AppAnnouncements from './components/announcements/index.vue';
  import {eventBus} from './/main';
  import AppAgreement from "@/components/article/AppAgreement.vue";
  import AppPrivacyPolicy from "@/components/article/AppPravicyPolicy.vue";

  export default {
    name: 'App',

    data() {
      return {
        basic_color: "#333"
      }
    },

    components: {
        AppPrivacyPolicy,
        AppAgreement,
      AppHeader, AppCart, AppProductCart, AppOrderSuccessSplash, AppFooter, AppBottomNavigation, AppFlyHeader, ArticleProductFastCart, AppAnnouncements, AppChat
    },

    computed: {
        cssProps() { return {
            '--main-font': this.getFont(),
            '--main-color': this.$root.hotelColor(),
            '--footer-color': this.$root.footerColor(),
            '--vh':  window.innerHeight * 0.01 + 'px',
            'padding-bottom': (this.$route.name == 'main') ? '400px' : '0px',
          }
        }
    },

    methods: {
      getFont() {
          return (this.$root.hotel && this.$root.hotel.font) ? this.$root.hotel.font : 'Ubuntu';
      },

      mainScroll(e) {
          eventBus.$emit('main-scroll', e);
      },

      openChat() {
            eventBus.$emit('open-chat');
      },

    }
}
</script>


<style src="@/assets/main.css">