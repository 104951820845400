<template>
  <div>
      <div class=" rounded-b-lg" :class="{'header' : ($root.template !== 'video_template' && $root.template !== 'slider_template'), 'py-[30px]' : ($root.template === 'video_template' || $root.template === 'slider_template')}">
        <div class="flex justify-between items-center h-full inside-header" :class="[$root.hotelCode === 'courtyardpolyana'|| $root.hotelCode === 'courtyardkazan' ? 'mx-4' : '']">                                  

            <div class="w-1/3" :class="{'flex' : checkScreen}" v-if="$root.hotelCode !== 'courtyardpolyana' && $root.hotelCode !== 'courtyardkazan'">
                <router-link  class=" mx-4 w-10 h-10 text-black bg-white/70 focus:outline-none rounded-full text-sm p-2.5 text-center inline-flex items-center shadow" id="homeBackButton" to="/home" v-if="$route.path == '/ar' || $route.path == '/ar-promo' || $route.path == '/locations-occupancy'">
                    <svg xmlns="http://www.w3.org/2000/svg"  class="w-5 h-5" style="fill: #000;" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
                </router-link>
<!--                <div class="mx-4" v-if="checkScreen">-->
<!--                    <div class="rounded-[20px] py-[12px] px-[15px] cursor-pointer flex justify-center items-center"-->
<!--                         :style="'background-color:' + $root.hotel.color"-->
<!--                         v-on:click="returnToFirstScreen">-->
<!--                        <svg width="18" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M7 17L7 1M7 1L13 7M7 1L1 7" stroke="white" stroke-width="2" stroke-linecap="round"-->
<!--                                  stroke-linejoin="round"/>-->
<!--                        </svg>-->
<!--                    </div>-->
<!--                </div>-->
            </div>



          <div class="w-2/3" :class="[$root.hotelCode === 'courtyardpolyana'|| $root.hotelCode === 'courtyardkazan' ? 'order-last' : '']" >
            <router-link class="flex  items-center" to="/home" id="logo" :class="[$root.hotelCode === 'courtyardpolyana'|| $root.hotelCode === 'courtyardkazan' ? 'justify-end' : 'justify-center']">
              <img :src="$root.logo" class="w-18 h-12" :class="[this.$root.template === 'blue' ? 'object-cover' : 'object-contain']" rel="logo">
            </router-link>
            <div id="logo2" style="display:none">
              <img :src="$root.logo" class="w-18 h-12" :class="[this.$root.template === 'blue' ? 'object-cover' : 'object-contain']" rel="logo">
            </div>
          </div>
          <div class="w-1/3 flex items-center" :class="[$root.hotelCode === 'courtyardpolyana'|| $root.hotelCode === 'courtyardkazan' ? 'justify-start mr-1' : 'justify-end']">
              <select-language-novotel-template v-if="$root.template === 'video_template' || $root.template === 'slider_template'" class="mx-4"></select-language-novotel-template>
              <select-language v-else class="mx-4"></select-language>
          </div>
        </div>        
      </div>
  </div>
</template>

<script>
import selectLanguageNovotelTemplate from './ArticleSelectLangNovotelTemplate.vue';
import selectLanguage from './ArticleSelectLang.vue';
import {eventBus} from "@/main";

export default {
  name: 'AppHeader',
  components: { selectLanguageNovotelTemplate, selectLanguage},
    methods: {
        returnToFirstScreen() {
            eventBus.$emit('scroll_to_first_screen');
        }
    },
    computed: {
        checkScreen() {
            let check;
            check = this.$route.name === 'home' && (this.$root.template === 'video_template' || this.$root.template === 'slider_template');
            return check;
        }
    }
}
</script>