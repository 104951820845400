<template>
    <div id="multiple-selector"></div>
</template>

<script>
    import AirDatepicker from 'air-datepicker';
    import 'air-datepicker/air-datepicker.css';
    import {AirDatePickerMixin} from '@/components/article/AirDatePickerMixin';

    export default {
        name: 'app_miltiple_date_picker',
        mixins: [AirDatePickerMixin],
        props: {
            clearForm: {
                type: Number,
                default: 0,
            },
            selector: String,
            minDate: {
                default: '1970-01-01',
            },
            multipleDates: {
                default: true,
                type: [Number, Boolean],
            },
        },
        data() {
            return {
                dates: [],
                datepicker: {},
            };
        },
        watch: {
            clearForm: function () {
                this.datepicker.clear();
            },
            appLocale: function(){
                this.setLocale();
                this.datepicker.update({locale: this.locale});
            },
        },
        computed: {
            appLocale(){
                return this.$root.currentLanguage;
            },
        },
        methods: {
            initDatePicker() {
                this.setLocale();
                let _this = this;
                this.datepicker = new AirDatepicker('#multiple-selector', {
                    inline: true,
                    minDate: this.minDate,
                    locale: this.locale,
                    multipleDates: this.multipleDates,
                    onSelect( {date}) {
                        _this.updateDates(date);
                    }
                });
            },
            /*
             * refresh dates by select on datepicker and emit data to top component
             */
            updateDates(date) {
                this.dates = date;
                this.$emit('updateDates', this.dates);
            },            

        },
        mounted() {
            this.initDatePicker();
        },
    }
</script>
<style>
    .air-datepicker {
        border: none!important;
        font-size: 18px;
    }

    .air-datepicker.-inline- {
        margin: auto;
        width: 342px;
    }

    .air-datepicker-body--day-names {
        gap: 2px;
        margin: 0 !important;
    }

    .air-datepicker-body--day-name {
        background: #D2CDCD!important;
        color: #646464!important;
        margin: 0 !important;
        padding: 6px 4px 3px 4px;
        border-radius: 2px;
    }

    .air-datepicker-cell.-selected- {
        background: #14103F!important;
        color: #ffffff!important;
        border-radius: 2px!important;
        height: 34.8px;
        width: 46px;
    }

    .air-datepicker-cell.-current- {
        color: #a19fb2!important;
    }

    .air-datepicker--content {
        border: none!important;
    }

    .air-datepicker-body--cells {
        gap: 2px;
    }

    .air-datepicker-body--cells.-days- {
        margin-top: 6px;
        grid-auto-rows: auto;
    }

    .air-datepicker-cell {
        height: 34.8px;
        width: 46px;
        border-radius: 2px!important;
    }
</style>