export const AdultsOnlyProductWarning = {
    methods: {
        checkIsAdultProduct(item) {
            let ask_user_is_adult = (localStorage.getItem('is_adult') === undefined || localStorage.getItem('is_adult') === null) && item.for_adult !== null && item.for_adult;

            if (ask_user_is_adult) {
                this.$modal.show('modal-extra', {item: item, section_id: null});
                return true;
            } else {
                return false;
            }
        }
    },
};

