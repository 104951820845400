<template>
    <div v-if="announcement">
        <modal dir="ltr" :name="'modal-announcements-' + announcement.id" :width="350" :height="'auto'" scrollable>
            <div class="w-full max-w-sm bg-white">            
                <div v-if="langImage" @click="openExternalLink">
                    <img class="rounded-t-lg" style="min-width: 350px;" :src="$root.getImage(langImage)" alt="product image" />
                </div>
                <div class="right-0 top-0 p-2 absolute">
                    <button @click="$modal.hide('modal-announcements-' + announcement.id)" class="text-gray-600 bg-gray-50 hover:bg-gray-200 p-1 opacity-75 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
                    </button>
                </div>

                <div 
                    @click="openExternalLink"
                    class="px-5 py-5" 
                    v-if="(announcement.extra.hide_title === undefined || announcement.extra.hide_title === null || announcement.extra.hide_title === false) || $root.getLangText(announcement.content)">
                    <div v-if="announcement.extra.hide_title === null || announcement.extra.hide_title === undefined || announcement.extra.hide_title === false" class="text-xl font-medium tracking-tight text-gray-900" v-html="$root.getLangText(announcement.title)"></div>
                    <div v-if="$root.getLangText(announcement.content)" class="mt-4"  v-html="$root.getLangText(announcement.content)"></div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        props: ['announcement'],


        computed: {
            langImage() {
                if (this.announcement) {
                    if (this.announcement.extra && this.announcement.extra.lang_file && this.announcement.extra.lang_file[this.$root.currentLanguage]) {
                        return this.announcement.extra.lang_file[this.$root.currentLanguage];
                    } else {
                        if (this.announcement.extra && this.announcement.extra.file !== undefined && this.announcement.extra.file !== null) {
                            return this.announcement.extra.file;
                        }
                    }
                }
                return undefined;
            }
        },

        methods: {
            openExternalLink(){
                if(this.announcement.extra.external_link && this.announcement.extra.external_link !== ''){
                    window.open(this.announcement.extra.external_link);
                }
            },

        }
    }
</script>